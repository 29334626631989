<template>
  <section class="player-line">
    <div class="player-line__avatar" @click="redirectToProfile">
      <SharedBadge
        v-if="player.level !== null"
        class="player-line__avatar-badge"
        :badge-color="levelColor"
        show-level
        :text="player.level"
      />
      <UiImage
        v-if="player.avatar"
        class="player-line__avatar-image"
        :src="player.avatar"
        :width="changeXL(44, 20)"
        :height="changeXL(44, 20)"
      />
    </div>
    <div class="player-line__texts">
      <div class="player-line__texts__bots-and-name">
        <BaseTooltip v-if="player.bot" position="bottom">
          {{ $t('battles.playerWindow.botInfo') }}
          <template #preview-icon>
            <LazySvgoPlayerBotBlueIcon :width="changeXL(24, 16)" :height="changeXL(24, 16)" />
          </template>
        </BaseTooltip>
        <SharedText class="player-line__texts-nickname" v-bind="textStyles" @click="redirectToProfile">
          {{ player.name }}
        </SharedText>
      </div>

      <SharedText v-if="!sumHide" class="player-line__texts-temp" v-bind="dropStyles">
        <BaseCurrency>{{ getValueRoundedByCurrency(sum) }}</BaseCurrency>
      </SharedText>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IPlayerLineProps } from './PlayerLine.types';

import { BattlesColors } from '~/features/battles/constants/colors';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';

const props = defineProps<IPlayerLineProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const { t } = useI18n();

const levelColor = computed(() => GlobalUtils.Colors.parseLvlColors(props.player.level ?? 0));

const textStyles = computed(() => ({
  size: changeXL(FontSizesNumber.LARGE, FontSizesNumber.SMALL),
  weight: FontWeights.BOLD,
}));

const dropStyles = computed(() => ({
  size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_XS),
  weight: FontWeights.BOLD,
}));

const offerData = computed(() => {
  const startWithHuman = props.offerType === 'start';

  return {
    color: GlobalUtils.CSS.getBackgroundColor(startWithHuman ? BattlesColors.MAIN : BattlesColors.BOT_MAIN),
    text: startWithHuman ? t('battles.playerWindow.startWithHuman') : t('battles.playerWindow.startWithBots'),
  };
});

const sumHide = computed(() => props.isCreated || props.isEnded);

const tempWinColors = computed(() => (props.sumWin ? BattlesColors.WIN : BattlesColors.LOSE));

const redirectToProfile = async () => {
  await navigateTo(`${useLinks().PROFILE}${props.player.userId}`, {
    open: {
      target: '_blank',
    },
  });
};
</script>

<style scoped lang="scss">
.player-line {
  --player-level: v-bind('levelColor');
  --offer-color: v-bind('offerData.color');
  --temp-win-color: v-bind('tempWinColors');
}
</style>

<style scoped lang="scss" src="./PlayerLine.scss" />
