<template>
  <div class="circle-container">
    <div class="bg-container" :class="bgClasses">
      <LazySvgoMxbg />
    </div>
    <div class="mc-logo-container" :class="logoClasses">
      <LazySvgoMcLogo />
    </div>
    <div class="arrow-container" :class="arrowClasses">
      <LazySvgoMxarrow />
    </div>
    <div class="circle" :class="circleClasses">
      <LazySvgoMxcircle />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IMulticastCircleProps, IMultiCircleEmits } from './MulticastCircle.types';
import { BattlesMulticastTimeouts } from '~/features/battles/constants/rules';

const props = defineProps<IMulticastCircleProps>();
const emits = defineEmits<IMultiCircleEmits>();

let dropTimeout: ReturnType<typeof setTimeout>;
const isAnimateDrop = ref(true);
const isHidden = ref(false);

const getRandomInterval = (min: number, max: number) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
};

const bgClasses = computed(() => ({ 'bg-action': isAnimateDrop.value, 'hide-element': isHidden.value }));

const circleClasses = computed(() => ({
  'circle-action': isAnimateDrop.value,
  'hide-element': isHidden.value,
}));

const arrowClasses = computed(() => ({ 'arrow-action': isAnimateDrop.value, 'hide-element': isHidden.value }));

const logoClasses = computed(() => ({ 'hide-element': isHidden.value, 'logo-action': isAnimateDrop.value }));

const showCircleOn = () => {
  isAnimateDrop.value = true;
  emits('start-circle-show');
  dropTimeout = setTimeout(() => {
    isAnimateDrop.value = false;
    isHidden.value = true;
  }, BattlesMulticastTimeouts.circleTime);
};

const circleOff = () => {
  clearTimeout(dropTimeout);
};

const randomAngleDeg = computed(() => {
  const angle = getRandomInterval(10, 80);
  // так как колесо вращается против часовой, а цифры мульти-каста написаны по часовой,
  // то проще сделать 4 ифака
  let calcAngle;
  const doubleTurn = 720;
  if (props.multiCount === 1) {
    calcAngle = doubleTurn + angle;
  }
  if (props.multiCount === 2) {
    calcAngle = doubleTurn + 270 + angle;
  }
  if (props.multiCount === 3) {
    calcAngle = doubleTurn + 180 + angle;
  }
  if (props.multiCount === 4) {
    calcAngle = doubleTurn + 90 + angle;
  }
  return `${calcAngle}deg`;
});

onMounted(() => {
  showCircleOn();
});

onUnmounted(() => {
  circleOff();
});
</script>

<style src="./MulticastCircle.scss" scoped lang="scss"></style>

<style scoped lang="scss">
.circle-container {
  --random-angle: v-bind(randomAngleDeg);
}
</style>
