<template>
  <section class="new-animation-container">
    <MulticastAnimation
      v-if="isMainAnimation"
      :drops="props.drops"
      @start-circle="emits('circle')"
      @start-fan-show="emits('fan')"
    />
    <RoundResultStatus
      v-if="isShowEndRoundCircleMulti"
      :class="{ 'end-battle': isLastRound }"
      :time-for-change="BattlesSpinnerTimeouts.growShrinkEndRoundCircle"
      :time-stable="timeInventory"
      :inner-color="innerColor"
      :outer-color="outerColor"
      :prize-line-color="prizeLineColor"
      :prize-line-show="isShowEndRoundCircleMulti"
      :is-last-round="isLastRound"
      :round-prizes-percent-of-total="roundPrizesPercentOfTotal"
    >
      <LazySvgoBattlesEnd v-if="isLastRound" />
      <LazySvgoWonBattleIcon v-else-if="isWon" />
      <LazySvgoLoseBattleIcon v-else />
    </RoundResultStatus>
  </section>
</template>

<script setup lang="ts">
import MulticastAnimation from '../../MulticastAnimation/MulticastAnimation.vue';
import RoundResultStatus from '../RoundResultStatus/RoundResultStatus.vue';
import type { IMultiNewEmits, IMultiNewProps } from './MultiNew.types';
import { BattlesColors } from '~/features/battles/constants/colors';
import { BattlesSpinnerTimeouts, BattlesMulticastTimeouts } from '~/features/battles/constants/rules';

const emits = defineEmits<IMultiNewEmits>();
const props = defineProps<IMultiNewProps>();

// круги в конце раунда для мультикаста|фикса
const isShowEndRoundCircleMulti = ref(false);

// показать рулетку и веер
const isMainAnimation = ref(false);

// timeouts
let dropMultiTimeout: ReturnType<typeof setTimeout>;
let swordShowTimeout: ReturnType<typeof setTimeout>;

const setNewRound = () => {
  // в начале раунда показать колесо + веер
  isMainAnimation.value = true;

  // время для анимации всего компонента MulticastAnimation
  dropMultiTimeout = setTimeout(() => {
    isMainAnimation.value = false;
    isShowEndRoundCircleMulti.value = true;
    if (props.isLastRound) emits('end-battle-animate-inventory');
  }, BattlesMulticastTimeouts.allTime);

  // убрать мечики промежуточного раунда
  swordShowTimeout = setTimeout(
    () => {
      isShowEndRoundCircleMulti.value = false;
      emits('end');
    },
    BattlesMulticastTimeouts.allTime +
      BattlesSpinnerTimeouts.growShrinkEndRoundCircle * 2 +
      BattlesSpinnerTimeouts.noChangeRoundCircle * 2 -
      3 * BattlesSpinnerTimeouts.defaultDelay +
      (props.isLastRound ? BattlesSpinnerTimeouts.noChangeLastRoundCircle : 0),
  );
};

const clearTimeoutsBeforeDestroy = () => {
  clearTimeout(dropMultiTimeout);
  clearTimeout(swordShowTimeout);
};

const timeInventory = computed(() => {
  return props.isLastRound
    ? BattlesSpinnerTimeouts.noChangeLastRoundCircle - BattlesMulticastTimeouts.differenceForLastRoundMulti
    : BattlesSpinnerTimeouts.noChangeRoundCircle;
});

const outerColor = computed(() => {
  if (props.isLastRound) return BattlesColors.END_BATTLE_7;
  return props.isWon ? BattlesColors.WIN_7 : BattlesColors.LOSE_7;
});

const innerColor = computed(() => {
  if (props.isLastRound) return BattlesColors.END_BATTLE_15;
  return props.isWon ? BattlesColors.WIN_15 : BattlesColors.LOSE_15;
});

const prizeLineColor = computed(() => {
  return props.isWon ? BattlesColors.WIN : BattlesColors.LOSE;
});

watch(
  () => props.number,
  (round) => {
    if (!round) return;
    setNewRound();
  },
);

onMounted(() => {
  setNewRound();
});

onUnmounted(() => {
  clearTimeoutsBeforeDestroy();
});
</script>

<style scoped lang="scss" src="./MultiNew.scss" />
