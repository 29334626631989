<template>
  <div class="emoji-picker" :class="emojiPickerClasses">
    <div v-if="props.isStatic && isTriggerVisible" class="emoji-picker__trigger">
      <LazySvgoEmodjiCollapseSmileIcon
        :class="[isOpened && !props.isStatic && 'emoji-picker__trigger--opened']"
        @click="handleTrigger"
      />
    </div>

    <div class="emoji-picker__list" :class="[!isOpened && !props.isStatic && 'emoji-picker__list--closed']">
      <div v-if="!props.isStatic" class="emoji-picker__trigger">
        <LazySvgoEmodjiCollapseSmileIcon
          :class="[isOpened && !props.isStatic && 'emoji-picker__trigger--opened']"
          @click="handleTrigger"
        />
      </div>

      <transition-group name="list-item">
        <template v-for="emoji in BattlesEmojiList" :key="emoji.key">
          <component :is="emoji.name" class="emoji-picker__list_item" @click="choseEmoji(emoji.key)" />
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AnimeInstance } from 'animejs';
import type { IEmitsBattleEmojiPicker, IPropsBattlesEmojiPicker } from './EmojiPicker.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { BattlesEmojiList } from '~/features/battles/constants/emojies';

const emits = defineEmits<IEmitsBattleEmojiPicker>();
const props = withDefaults(defineProps<IPropsBattlesEmojiPicker>(), {
  direction: 'vertical',
  isStatic: false,
  openOnRender: false,
});

const isTriggerVisible = ref(true);
const isOpened = ref(false);

const emojiPickerClasses = computed(() => ({
  'emoji-picker--horizontal': props.direction === 'horizontal',
  'emoji-picker--no-static': !props.isStatic,
  'emoji-picker--static': props.isStatic,
  'emoji-picker--vertical': props.direction === 'vertical',
}));

let animationOnTrigger: TPossibleNull<AnimeInstance> = null;

const animateStaticMounted = () => {
  const $trigger = document.querySelector('.emoji-picker__trigger') as HTMLElement;
  const $list = document.querySelector('.emoji-picker__list') as HTMLElement;
  const $picker = document.querySelector('.emoji-picker') as HTMLElement;

  const { finished: firstFinished } = useAnime({
    delay: 500,
    duration: 500,
    easing: 'easeInOutSine',
    opacity: [0, 1],
    scale: [1, 1.5, 0.8],
    targets: $trigger,
  });

  const doSecond = () => {
    $trigger.style.display = 'none';
    $list.style.display = 'flex';
    $picker.style.backgroundColor = 'var(--neutral-black-50)';
    isTriggerVisible.value = false;
  };

  const doThird = () => {
    useAnime({
      duration: 500,
      easing: 'easeInSine',
      opacity: [0, 1],
      scale: [0.8, 1],
      targets: $list,
    });
  };

  firstFinished.then(() => {
    doSecond();
    doThird();
  });
};

const animateNoStaticOnMounted = () => {
  const $picker = document.querySelector('.emoji-picker') as HTMLElement;
  const $items = [...document.querySelectorAll('.emoji-picker__list_item')] as HTMLElement[];

  const { finished: firstFinished } = useAnime({
    backgroundColor: 'var(--neutral-black-35)',
    duration: 300,
    targets: $picker,
  });

  firstFinished.then(doSecond);

  function doSecond() {
    const { finished } = useAnime({
      delay: (...args) => args[1] * 85,
      duration: $items.length * 85,
      easing: 'easeInOutSine',
      opacity: [0, 1],
      scale: [0.8, 1],
      targets: $items,
    });

    finished.then(doFinal);
  }

  function doFinal() {
    isOpened.value = !isOpened.value;
  }
};

const choseEmoji = (emojiKey: string) => {
  emits('onChosen', emojiKey);
};
const handleTrigger = () => {
  if (props.isStatic) return;

  const $picker = document.querySelector('.emoji-picker') as HTMLElement;
  const $items = [...document.querySelectorAll('.emoji-picker__list_item')] as HTMLElement[];

  if (animationOnTrigger && animationOnTrigger.progress < 100) {
    animationOnTrigger.pause();

    animationOnTrigger.reverse();
    animationOnTrigger.play();

    animationOnTrigger.finished.then(doOnFinish);

    return;
  }

  const willBeHidden = isOpened.value;

  const timeline = useAnime.timeline({
    duration: 250,
    easing: 'linear',
  });

  animationOnTrigger = timeline;

  const isGoingToBeShown = () => {
    useAnime.set($picker, {
      backgroundColor: 'transparent',
    });

    timeline.add({
      backgroundColor: 'var(--neutral-black-30)',
      paddingBottom: '10px',
      targets: $picker,
    });

    timeline.add({
      delay: (...args) => args[1] * 85,
      easing: 'easeInOutSine',
      opacity: [0, 1],
      targets: $items,
    });
  };

  const isGoingToBeHidden = () => {
    timeline.add({
      delay: (...args) => ($items.length - 1 - args[1]) * 85,
      easing: 'easeInOutSine',
      opacity: [1, 0],
      targets: $items,
    });
  };

  function doOnFinish() {
    isOpened.value = !isOpened.value;
    !isOpened.value && ($picker.style.backgroundColor = 'transparent');
  }

  if (willBeHidden) isGoingToBeHidden();
  else isGoingToBeShown();

  timeline.finished.then(doOnFinish);
};

onMounted(() => {
  if (!props.openOnRender) return;
  if (props.isStatic) animateStaticMounted();
  else animateNoStaticOnMounted();
});
</script>

<style src="./EmojiPicker.scss" scoped lang="scss"></style>
