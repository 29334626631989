<template>
  <div :class="['battles-action-buttons-group', isThereHiddenBtn && 'battles-action-buttons-group--single']">
    <slot v-if="!hideButtons.top" name="top">
      <SharedKitButton
        v-if="!props.isSwiped"
        class="battles-action-buttons-group__button battles-action-buttons-group__button--main"
        :is-disabled="props.isMainDisabled"
        :is-loading="props.isMainLoading || props.isSecondaryLoading"
        v-bind="presetBtn('primary', { typePreset: defineRadiusForButtons('top') })"
        :text="mainButtonTitle"
        @click="emits('onMainClick')"
      />
      <SharedKitButton
        v-else
        class="battles-action-buttons-group__button battles-action-buttons-group__button--watch"
        v-bind="presetProgressBtn('secondary', { typePreset: defineRadiusForButtons('top') })"
        :text="staticButtonTitle"
      >
        <template v-if="props.watchers && isNumber(+props.watchers)" #append>
          <div class="battles-action-buttons-group__watchers">
            <LazySvgoEyeIcon class="battles-action-buttons-group__watchers_icon" />
            <SharedText class="battles-action-buttons-group__watchers_text" :weight="FontWeights.BOLD">
              {{ props.watchers }}
            </SharedText>
          </div>
        </template>
      </SharedKitButton>
    </slot>
    <slot v-if="!hideButtons.bottom" name="bottom">
      <SharedKitButton
        class="battles-action-buttons-group__button battles-action-buttons-group__button--secondary"
        v-bind="presetBtn('secondary', { typePreset: defineRadiusForButtons('bottom') })"
        :text="secondaryButtonTitle"
        :is-loading="props.isSecondaryLoading || props.isMainLoading"
        :is-disabled="props.isSecondaryDisabled"
        @click="emits('onSecondaryClick')"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { IActionButtonsGroupEmits, IActionButtonsGroupProps } from './ActionButtonsGroup.types';
import { Colors } from '~/constants/colors.constants';
import { FontWeights } from '~/types/SharedFont.types';
import { BattlesColors } from '~/features/battles/constants/colors';
import { createPresetor } from '~/features/battles/presets/buttons.presets';
import { defineRadiusForButtons } from '~/features/battles/constants/actionsButtonsGroup';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const { t } = useI18n();

const emits = defineEmits<IActionButtonsGroupEmits>();
const props = withDefaults(defineProps<IActionButtonsGroupProps>(), {
  hideButtons: () => ({
    bottom: false,
    top: false,
  }),
  isMainDisabled: false,
  isMainLoading: false,
  isSecondaryDisabled: false,
  isSecondaryLoading: false,
  isSwiped: false,
});

const mainButtonTitle = computed(() => props.mainButtonTitle || t('battles.participate'));
const secondaryButtonTitle = computed(() => props.secondaryButtonTitle || t('battles.watch'));
const staticButtonTitle = computed(() => props.staticButtonTitle || t('battles.inProcess'));

const isThereHiddenBtn = computed(() => {
  return props.hideButtons.top || props.hideButtons.bottom;
});

const presetBtn = computed(() =>
  createPresetor(changeXl, {
    typePreset: {
      large: 0,
      medium: 0,
    },
  }),
);

const presetProgressBtn = computed(() =>
  createPresetor(changeXl, {
    colorPreset: {
      defaultBackground: Colors.NEUTRAL.BLACK_30,
      textColor: {
        colorStops: BattlesColors.MAIN_LIGHT,
        toDirection: '45deg',
      },
    },
    typePreset: {
      large: 0,
      medium: 0,
    },
  }),
);

function isNumber(value: number | string): boolean {
  const converted = parseInt(value?.toString(), 10);
  return converted === 0 || !!value;
}
</script>

<style src="./ActionButtonsGroup.scss" scoped lang="scss"></style>
