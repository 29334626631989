export const enum EmojiesListsKeys {
  BATTLE_WINDOW = 'BATTLE_WINDOW',
  FINISH_POPUP = 'FINISH_POPUP',
}

export class EmojiItem {
  name: string;
  key: string;

  constructor(emojiName: string) {
    this.name = emojiName;
    this.key = emojiName;
  }
}

export const BattlesEmojiList = [
  new EmojiItem('LazySvgoEmodjiCryingBaby'),
  new EmojiItem('LazySvgoEmodjiFrogSweats'),
  new EmojiItem('LazySvgoEmodjiGrandpaLaugh'),
  new EmojiItem('LazySvgoEmodjiManYawns'),
  new EmojiItem('LazySvgoEmodjiPressF'),
  new EmojiItem('LazySvgoEmodjiRedBlockSmile'),
  new EmojiItem('LazySvgoEmodjiRoflanFace'),
];
