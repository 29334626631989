<template>
  <SharedContainer class="running-battle-page" xl>
    <BattlesPageTitleWidget
      v-if="!hasFetchError"
      class="running-battle-page__title"
      :title="$t('battlesTitle')"
      :show-rag="false"
    />
    <ClientOnly>
      <RunningBattleController v-if="battle" class="running-battle-page__process" />
      <NotFoundBattlePage v-if="hasFetchError && !battle"></NotFoundBattlePage>
    </ClientOnly>
  </SharedContainer>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import NotFoundBattlePage from '../NotFoundBattlePage/NotFoundBattlePage.vue';

import RunningBattleController from '~/features/battles/controllers/RunningBattleController/RunningBattleController.vue';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { usePlayersStore } from '~/features/battles/store/players.store';

definePageMeta({
  layout: 'battles',
});

const route = useRoute();

const singleBattleStore = useSingleBattleStore();
const playersStore = usePlayersStore();

const { battle, hasFetchError } = storeToRefs(singleBattleStore);

if (!singleBattleStore.isFromCreation) {
  await singleBattleStore.fetchBattle(route.params.battleId as string);
} else {
  singleBattleStore.isFromCreation = false;
}

function doOnGoAway() {
  if (!playersStore.isMeHost && !playersStore.isMePlayer) {
    singleBattleStore.closePage();
  }
}

function doOnEnter() {
  if (!playersStore.isMeHost && !playersStore.isMePlayer) {
    singleBattleStore.openPage();
  }
}

onBeforeRouteLeave(() => {
  doOnGoAway();
});

onMounted(() => {
  doOnEnter();

  window && window.addEventListener('beforeunload', doOnGoAway);
});

onUnmounted(() => {
  window && window.removeEventListener('beforeunload', doOnGoAway);
  singleBattleStore.$reset();
  playersStore.$reset();
});
</script>

<style lang="scss">
body.overflow-hidden {
  height: 100vh;
  overflow: hidden;
}
</style>

<style lang="scss" scoped src="./RunningBattlePage.scss" />
