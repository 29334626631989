<template>
  <div class="shared-drop-coin">
    <span class="shared-drop-coin__count" :style="countStyle">+ {{ count }}</span>
    <UiImage class="shared-drop-coin__image" :style="imageStyle" :src="eventStore.coinIcon" />
    <BaseTooltip class="shared-drop-coin__tooltip-trigger" position="bottom" append-to-body>
      <TooltipDescriptionCoin />
    </BaseTooltip>
    <span class="shared-drop-coin__label">Tasty Coins</span>
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import type { SharedDropCoinProps } from './SharedDropCoin.types';

defineProps<SharedDropCoinProps>();

const eventStore = useEventStore();

const countStyle = computed(() => ({
  color: eventStore.coinColor,
}));

const imageStyle = computed(() => ({
  filter: `drop-shadow(0 0 10px ${eventStore.coinColor})`,
}));
</script>

<style lang="scss" src="./SharedDropCoin.scss" />
